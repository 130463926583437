import { RequestBannerListApi } from "@/domain/entities/BannerConfig";

export class CMSBannerEndpoint {
  getListBanner(params: RequestBannerListApi): string {
    return `/horde/v1/configuration-banner?${params.toQueryString()}`;
  }

  createConfigBanner() {
    return `/horde/v1/configuration-banner`;
  }

  getDetailBanner(id: number): string {
    return `/horde/v1/configuration-banner/${id}`;
  }
}
