import { CMSBannerPresenter } from "@/app/ui/presenters/CMSBannerPresenter";
import { CMSBannerMapper } from "@/data/persistences/mappers/CMSBannerMapper";
import { container } from "tsyringe";
import { CMSBannerEndpoint } from "../../endpoints/horde/CMSBannerEndpoint";
import { CMSBannerApiRepository } from "../../repositories/api/CMSBannerApiRepository";
import ApiService from "../../services/ApiService";

export class CMSBannerComponent {
  public static init() {
    container.register<CMSBannerApiRepository>(CMSBannerApiRepository, {
      useFactory: d => {
        return new CMSBannerApiRepository(
          d.resolve(ApiService),
          d.resolve(CMSBannerMapper),
          d.resolve(CMSBannerEndpoint)
        );
      }
    });
    container.register<CMSBannerMapper>(CMSBannerMapper, {
      useClass: CMSBannerMapper
    });
    container.register<CMSBannerPresenter>(CMSBannerPresenter, {
      useFactory: d => {
        return new CMSBannerPresenter(d.resolve(CMSBannerApiRepository));
      }
    });
  }
}
