import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative" }
const _hoisted_2 = { class: "font-semibold self-center mr-10 text-20px whitespace-no-wrap text-black-lp-200 capitalize" }
const _hoisted_3 = { class: "flex flex-row justify-between items-center" }
const _hoisted_4 = { class: "flex z-20 w-3/5 gap-x-3 pt-8 pb-4" }
const _hoisted_5 = { class: "relative flex justify-center overflow-auto h-screen" }
const _hoisted_6 = { class: "overflow-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Search = _resolveComponent("Search")!
  const _component_Select = _resolveComponent("Select")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Header, { class: "items-center" }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_2, _toDisplayString(_ctx.getTitle), 1)
      ]),
      _: 1
    }),
    _createVNode("div", _hoisted_3, [
      _createVNode("div", _hoisted_4, [
        _createVNode(_component_Search, {
          class: "w-full self-center",
          onInput: _ctx.onSearch,
          onClear: () => _ctx.onSearch(''),
          value: _ctx.bannerApiList.request.search,
          placeholder: "Cari nama Banner",
          minChar: 1,
          timeout: 1000
        }, null, 8, ["onInput", "onClear", "value"]),
        _createVNode(_component_Select, {
          dataSelect: _ctx.statusData,
          onOpenSelect: () => _ctx.onOpenStatus(true),
          onCloseSelect: () => _ctx.onOpenStatus(false),
          onChooseSelect: _ctx.onSelectStatus,
          value: _ctx.bannerApiList.request.status,
          isOpenMenu: _ctx.statusSelect,
          class: "w-full"
        }, null, 8, ["dataSelect", "onOpenSelect", "onCloseSelect", "onChooseSelect", "value", "isOpenMenu"])
      ]),
      _withDirectives(_createVNode(_component_lp_button, {
        class: "pt-6",
        onClick: _ctx.goToButtonCreate,
        title: "Buat Banner",
        textColor: "white"
      }, null, 8, ["onClick"]), [
        [_vShow, _ctx.isCreateAble]
      ])
    ]),
    _createVNode("div", _hoisted_5, [
      _createVNode("div", _hoisted_6, [
        _createVNode(_component_TableV2, {
          class: "my-2",
          borderTop: false,
          isEmpty: _ctx.isEmpty,
          isFilter: _ctx.isFilter,
          columns: _ctx.columns,
          data: _ctx.bannerApiList.response.data,
          errorCause: _ctx.apiListData.errorType,
          onTryAgain: _ctx.fetchData,
          loading: _ctx.apiListData.loading,
          pagination: _ctx.bannerApiList.response.pagination,
          "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.bannerApiList.response.pagination = $event)),
          onRequest: _ctx.fetchData,
          isDetailAble: _ctx.isDetailAble,
          onClick: _ctx.goToDetail,
          paginationStyle: "v3",
          isPinnedScrollBottom: ""
        }, null, 8, ["isEmpty", "isFilter", "columns", "data", "errorCause", "onTryAgain", "loading", "pagination", "onRequest", "isDetailAble", "onClick"])
      ])
    ])
  ]))
}