/* eslint-disable @typescript-eslint/no-unused-vars */
import ConvertObjectCamelToSnakeCase from "@/app/infrastructures/misc/common-library/ConvertObjectCamelToSnakeCase";
import toFormDataUtils from "@/app/infrastructures/misc/common-library/ToFormDataUtils";
import { BannerConfigData } from "@/domain/entities/BannerConfig";
import { BannerConfigurationRequest } from "../contracts/CMSBannerRequest";

export class CreateBannerConfigRequest implements BannerConfigurationRequest {
  payload: BannerConfigData = new BannerConfigData();

  constructor(fields?: Partial<BannerConfigData>) {
    Object.assign(this.payload, fields);
  }
  toFormData(): FormData {
    const {
      id,
      isActive,
      image,
      createdAt,
      createdBy,
      updatedAt,
      updatedBy,
      ...payload
    } = this.payload;
    return toFormDataUtils(ConvertObjectCamelToSnakeCase(payload));
  }
}
