
import { Vue } from "vue-class-component";
import { CMSBannerController } from "@/app/ui/controllers/CMSBannerController";
import {
  BannerApiList,
  BannerConfigData,
  RequestBannerListApi
} from "@/domain/entities/BannerConfig";
import { ChipsStyles } from "@/app/ui/components/chips-v2/modules";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { CMS_BANNER } from "@/app/infrastructures/misc/RolePermission";
import { ApiStateData } from "@/domain/entities/Api";

export default class CMSBanner extends Vue {
  get isCreateAble() {
    return checkRolePermission(CMS_BANNER.CREATE);
  }
  get isDetailAble() {
    return checkRolePermission(CMS_BANNER.DETAIL);
  }

  mounted() {
    this.fetchData();
  }
  get getTitle() {
    return this.$route.meta.title;
  }

  apiListData = new ApiStateData();
  bannerApiList = new BannerApiList();

  firstPagination() {
    this.bannerApiList.response.pagination.page = 1;
  }
  async fetchData() {
    try {
      this.apiListData.loading = true;
      this.bannerApiList.response = await CMSBannerController.getBannerList(
        new RequestBannerListApi({
          page: this.bannerApiList.response.pagination.page,
          limit: this.bannerApiList.response.pagination.limit,
          search: this.bannerApiList.request.search,
          status: this.bannerApiList.request.status
        })
      );
      this.apiListData.errorType = "";
    } catch (error) {
      this.apiListData.errorType = parsingErrorResponse(error).type;
    } finally {
      this.apiListData.loading = false;
    }
  }
  get isEmpty() {
    return (
      !this.bannerApiList.response.data.length && !this.apiListData.loading
    );
  }
  get isFilter() {
    return (
      !!this.bannerApiList.request.search && !!this.bannerApiList.request.status
    );
  }

  columns = [
    {
      name: "ID",
      styleHead: "w-1/20 text-left",
      render: (item: BannerConfigData) => {
        return `<div class="text-black-lp-300">${item.id}</div>`;
      }
    },
    {
      name: "Nama Banner",
      styleHead: "w-1/4 text-left",
      render: (item: BannerConfigData) => {
        return `<div class="text-black-lp-300">${item.name}</div>`;
      }
    },
    {
      name: "Tanggal Mulai",
      styleHead: "w-1/5 text-left",
      render: (item: BannerConfigData) => {
        return `<div class='text-black-lp-300  flex flex-row'>
                ${item.formatStartDate}
                </div>`;
      }
    },
    {
      name: "Tanggal Berakhir",
      styleHead: "w-1/5 text-left",
      render: (item: BannerConfigData) => {
        return `<div class='text-black-lp-300  flex flex-row'>
                ${item.formatEndDate}
                </div>`;
      }
    },
    {
      name: "Priority",
      styleHead: "w-3/20 text-left",
      render: (item: BannerConfigData) => {
        return `<div class="text-black-lp-300">${item.priority}</div>`;
      }
    },
    {
      name: "Status",
      styleHead: "w-2/20 text-left",
      render: (item: BannerConfigData) => ChipsStyles({ status: item.status })
    }
  ];

  // filter search
  onSearch(value: string) {
    if (!value.length || value.length >= 3) {
      this.bannerApiList.request.search = value;
      this.firstPagination();
      this.fetchData();
    }
  }
  // status
  statusData = [
    { name: "Pilih Status", value: "" },
    { name: "Active", value: "active" },
    { name: "Inactive", value: "inactive" },
    { name: "Waiting", value: "waiting" }
  ];
  statusSelect = false;
  onOpenStatus(value: boolean) {
    this.statusSelect = value;
  }
  onSelectStatus(_: string, value: string) {
    this.bannerApiList.request.status = value;
    this.onOpenStatus(false);
    this.firstPagination();
    this.fetchData();
  }

  //button create
  goToButtonCreate() {
    this.$router.push("/cms-banner/create");
  }

  goToDetail(item: BannerConfigData) {
    this.$router.push(`/cms-banner/${item.id}`);
  }
}
