import { CMSBannerApiRepository } from "@/app/infrastructures/repositories/api/CMSBannerApiRepository";
import { CreateBannerConfigRequest } from "@/data/payload/api/CMSBannerRequest";
import {
  BannerConfigData,
  BannerListEntities,
  RequestBannerListApi
} from "@/domain/entities/BannerConfig";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { injectable } from "tsyringe";

@injectable()
export class CMSBannerPresenter {
  private repository: CMSBannerApiRepository;

  constructor(repository: CMSBannerApiRepository) {
    this.repository = repository;
  }

  public getBannerList(
    params: RequestBannerListApi
  ): Promise<BannerListEntities> {
    return this.repository.getBannerList(params);
  }

  public getBannerDetail(id: number): Promise<BannerConfigData> {
    return this.repository.getBannerDetail(id);
  }

  public createBannerConfig(
    params: CreateBannerConfigRequest
  ): Promise<ResponsePayloadV2> {
    return this.repository.createBannerConfig(params);
  }

  public updateBannerConfig(
    params: CreateBannerConfigRequest,
    id: number
  ): Promise<ResponsePayloadV2> {
    return this.repository.updateBannerConfig(params, id);
  }
}
