import ApiService from "../../services/ApiService";
import { CMSBannerMapper } from "@/data/persistences/mappers/CMSBannerMapper";
import {
  BannerConfigData,
  BannerListEntities,
  RequestBannerListApi
} from "@/domain/entities/BannerConfig";
import { CMSBannerRepositoryInterface } from "@/data/persistences/repositories/contracts/CMSBannerRepositoryInteface";
import { BannerConfigurationRequest } from "@/data/payload/contracts/CMSBannerRequest";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { CreateBannerConfigRequest } from "@/data/payload/api/CMSBannerRequest";
import { CMSBannerEndpoint } from "../../endpoints/horde/CMSBannerEndpoint";

export class CMSBannerApiRepository implements CMSBannerRepositoryInterface {
  private service: ApiService;
  private mapper: CMSBannerMapper;
  private endpoints: CMSBannerEndpoint;

  constructor(
    service: ApiService,
    mapper: CMSBannerMapper,
    endpoints: CMSBannerEndpoint
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getBannerList(
    params: RequestBannerListApi
  ): Promise<BannerListEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListBanner(params),
      {}
    );
    return this.mapper.convertListDataFromApi(resp);
  }

  public async getBannerDetail(id: number): Promise<BannerConfigData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailBanner(id),
      {}
    );
    return this.mapper.convertDetailDataFromApi(resp);
  }

  public async createBannerConfig(
    payload: BannerConfigurationRequest
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invokePostWithFormData(
      "post",
      this.endpoints.createConfigBanner(),
      {},
      payload as CreateBannerConfigRequest
    );
    return this.mapper.convertBannerConfigCreateDataFromApi(resp);
  }

  public async updateBannerConfig(
    payload: BannerConfigurationRequest,
    id: number
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invokePostWithFormData(
      "patch",
      this.endpoints.getDetailBanner(id),
      {},
      payload as CreateBannerConfigRequest
    );
    return this.mapper.convertBannerConfigCreateDataFromApi(resp);
  }
}
