import {
  BannerConfigData,
  BannerListEntities
} from "@/domain/entities/BannerConfig";
import { AxiosResponse } from "axios";
import { PaginationV2 } from "@/domain/entities/Pagination";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import firstCapitalize from "@/app/infrastructures/misc/common-library/FirstCapitalize";
import parsingLinkGcpToAws from "@/app/infrastructures/misc/common-library/ParsingLinkGcpToAws";

export class CMSBannerMapper {
  public convertListDataFromApi(res: AxiosResponse<any>): BannerListEntities {
    const { data } = res;

    if (!data.data.length) {
      return new BannerListEntities({
        pagination: new PaginationV2({
          page: data.meta.page,
          limit: data.meta.limit,
          totalData: data.meta.total_records
        })
      });
    }
    return new BannerListEntities({
      pagination: new PaginationV2({
        page: data.meta.page,
        limit: data.meta.limit,
        totalData: data.meta.total_records
      }),
      data: data.data.map(
        (item: any) =>
          new BannerConfigData({
            id: item.cb_id,
            name: item.cb_name,
            startDate: item.cb_start_date,
            endDate: item.cb_end_date,
            priority: item.cb_priority,
            isActive: new BannerConfigData().bannerStatus(item.cb_status),
            status: item.cb_status
          })
      )
    });
  }

  public convertBannerConfigCreateDataFromApi(
    res: AxiosResponse<any>
  ): ResponsePayloadV2 {
    const { data } = res;
    return new ResponsePayloadV2({
      success: data.success,
      message: data.message,
      code: data.code
    });
  }

  public convertDetailDataFromApi(res: AxiosResponse<any>): BannerConfigData {
    const {
      data: { data }
    } = res;
    const target = data.target_banner.length
      ? data.target_banner.split(",").map((key: any) => firstCapitalize(key))
      : "";
    return new BannerConfigData({
      id: data.id,
      name: data.name,
      startDate: data.start_date,
      endDate: data.end_date,
      priority: data.priority,
      status: data.status,
      target: target,
      url: data.url,
      image: parsingLinkGcpToAws(data.banner_image),
      createdAt: data.created_at,
      createdBy: data.created_by,
      updatedAt: data.updated_at,
      updatedBy: data.updated_by,
      isActive: data.status !== "inactive"
    });
  }
}
