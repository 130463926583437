import {
  formatDate,
  formatDateWithoutTime
} from "@/app/infrastructures/misc/Utils";
import { QueryParamsEntities } from "./MainApp";
import { PaginationV2 } from "./Pagination";

export class RequestBannerListApi {
  page = 1;
  limit = 20;
  search = "";
  status = "";

  constructor(fields?: Partial<RequestBannerListApi>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class BannerConfigData {
  id = 0;
  name = "";
  target: string | Array<any> = [];
  priority: any = "";
  startDate: Date | string = "";
  endDate: Date | string = "";
  url = "";
  status = "";
  isActive = true;
  bannerUrl: any = "";
  image = "";
  createdAt = "";
  createdBy = "";
  updatedAt = "";
  updatedBy = "";
  constructor(fields?: Partial<BannerConfigData>) {
    Object.assign(this, fields);
  }

  bannerStatus(status: string): boolean {
    return !!status.match(/^active|^waiting/gi);
  }

  get formatStartDate() {
    return formatDateWithoutTime(this.startDate);
  }

  get formatEndDate() {
    return formatDateWithoutTime(this.endDate);
  }

  get formatCreatedAt() {
    return formatDate(this.createdAt);
  }

  get formatUpdatedAt() {
    return formatDate(this.updatedAt);
  }
}

export class BannerListEntities {
  pagination = new PaginationV2();
  data = [] as BannerConfigData[];

  constructor(fields?: Partial<BannerListEntities>) {
    Object.assign(this, fields);
  }
}

export class BannerApiList {
  request = new RequestBannerListApi();
  response = new BannerListEntities();

  constructor(fields?: Partial<BannerApiList>) {
    Object.assign(this, fields);
  }
}
