
import { CMSBannerController } from "@/app/ui/controllers/CMSBannerController";
import { Vue } from "vue-class-component";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { CMS_BANNER } from "@/app/infrastructures/misc/RolePermission";
import { ApiStateData } from "@/domain/entities/Api";
import { BannerConfigData } from "@/domain/entities/BannerConfig";

export default class DetailCMSBanner extends Vue {
  get isEditAble() {
    return checkRolePermission(CMS_BANNER.EDIT);
  }

  goBack() {
    this.$router.push(`/cms-banner`);
  }

  mounted() {
    this.fetchDetail();
  }

  get id(): number {
    return Number(this.$route.params.id);
  }

  apiDetailData = new ApiStateData();
  detailData = new BannerConfigData();

  async fetchDetail() {
    try {
      this.apiDetailData.loading = true;
      this.detailData = await CMSBannerController.getBannerDetail(this.id);
      this.apiDetailData.errorType = "";
    } catch (error) {
      this.apiDetailData.errorType = parsingErrorResponse(error).type;
    } finally {
      this.apiDetailData.loading = false;
    }
  }
  get roleTarget() {
    if (!String(this.detailData.target)) return "";
    return String(this.detailData.target).replaceAll(",", ", ");
  }

  goToEdit() {
    this.$router.push(`/cms-banner/${this.id}/edit`);
  }
}
