import { Action, getModule, Module, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { container } from "tsyringe";
import { CMSBannerPresenter } from "../presenters/CMSBannerPresenter";
import { CreateBannerConfigRequest } from "@/data/payload/api/CMSBannerRequest";
import { RequestBannerListApi } from "@/domain/entities/BannerConfig";
import { CMSBannerComponent } from "@/app/infrastructures/dependencies/modules/CMSBannerComponent";
CMSBannerComponent.init();
@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "cms-banner"
})
class CMSBannerStore extends VuexModule {
  @Action
  public getBannerList(params: RequestBannerListApi) {
    const presenter = container.resolve(CMSBannerPresenter);
    return presenter.getBannerList(params);
  }

  @Action
  public getBannerDetail(id: number) {
    const presenter = container.resolve(CMSBannerPresenter);
    return presenter.getBannerDetail(id);
  }

  @Action
  public createBannerConfig(params: CreateBannerConfigRequest) {
    const presenter = container.resolve(CMSBannerPresenter);
    return presenter.createBannerConfig(params);
  }

  @Action
  public updateBannerConfig(params: {
    payload: CreateBannerConfigRequest;
    id: number;
  }) {
    const presenter = container.resolve(CMSBannerPresenter);
    return presenter.updateBannerConfig(params.payload, params.id);
  }
}
export const CMSBannerController = getModule(CMSBannerStore);
